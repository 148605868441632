<template>
    <EditBase :loading="loading" :edicao="edicao" :nome="grupoUsuario.nome" placeholder="Novo grupo de usuários">
        <form @submit="formSalvar" v-if="!loading">
            <div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="i_nome">Nome *</label>
                        <input type="text" required="required" :disabled="saving" class="form-control" id="i_nome" placeholder="Exemplo: Administrador" v-model="grupoUsuario.nome" :readonly="grupoUsuario.id === 1" data-cy="Nome">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="i_descrição">Descrição</label>
                        <input type="text" maxlength="120" :disabled="saving" class="form-control" id="i_descrição" placeholder="Exemplo: Possui todas as permissões do sistema" v-model="grupoUsuario.descricao" data-cy="Descrição">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="i_nome">Permissões *</label>
                        <vue-multi-select popoverClass="w-100" class="multi-100" :disabled="saving" v-model="selectedPermitions" search historyButton :filters="multiSelectFilters" :options="multiSelectOptions" :selectOptions="permissoes" :btnLabel="() => customLabel(selectedPermitions)"/>
                    </div>
                </div>
                <br>
                <div class="d-flex justify-content-between pb-4">
                    <div class="align-self-center">
                        <h2>Notificações de alarmes</h2>
                    </div>
                    <div class="align-self-center">
                        <button type="button" class="btn btn-info" data-cy="Adicionar" :disabled="classesAlarmesSeveridade.length === listaClassesAlarmes.length" @click="addClassesAlarmesSeveridade">Adicionar</button>
                    </div>
                </div>

                <div class="table-responsive-md">
                <table class="table" data-cy="TabelaDetectores" v-show="true">
                    <thead>
                        <tr>
                            <th>Severidade *</th>
                            <th>Classe de alarme *</th>
                            <th class="w-custom"></th>
                        </tr>
                    </thead>
                     <tbody v-show="!classesAlarmesSeveridade || classesAlarmesSeveridade.length === 0">
                        <tr><td colspan="3" class="text-center">Nenhuma classe de alarme selecionada.</td></tr>
                    </tbody>
                    <tbody v-show="classesAlarmesSeveridade && classesAlarmesSeveridade.length > 0">
                        <tr v-for="(td, idx) in classesAlarmesSeveridade" :key="td.id" class="destaque" :id="`form-class-${td.id}`">
                            <td>
                                <multiselect id="i_perms" v-model="td.severidade" label="text" track-by="value" :hide-selected="true" :clear-on-select="true" :disabled="saving" :multiple="false" :show-labels="false" :searchable="true" placeholder="Selecione"  :options="listaSeveridades" :data-cy="`NotificaçãoSeveridade${idx}`"></multiselect>
                            </td>
                            <td>
                                <multiselect id="i_perms" v-model="td.classe" label="text" track-by="value" :hide-selected="true" :clear-on-select="true" :disabled="saving" :multiple="false" :show-labels="false" :searchable="true" placeholder="Selecione"  :options="classesAlarmes" :data-cy="`NotificaçãoClasse de alarme${idx}`"></multiselect>
                            </td>
                            <td class="w-custom">
                                <button type="button" class="form-control btn btn-warning" :data-cy="`RemoverClasse${idx}`" @click="removeClasse(td)">Remover</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

                <p class="small text-right">Campos marcados com * são obrigatórios.</p>
            </div>
            <div v-if="edicao" class="d-flex justify-content-between">
                <button class="btn btn-danger align-self-center lg" type="button" @click="remover" :disabled="loading || saving || !edicao" data-cy="Remover">Remover</button>
                <button class="btn btn-success align-self-center lg" type="submit" :disabled="loading || saving || !isComplete" data-cy="Salvar">Salvar</button>
            </div>
            <div v-else class="d-flex justify-content-end">
                <button class="btn btn-success align-self-center lg" type="submit" :disabled="loading || saving || !isComplete" data-cy="Salvar">Salvar</button>
            </div>
        </form>
    </EditBase>
</template>

<script>

function options (timeout) {
    return {
        timeout: timeout || 2000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
    }
}

import { copyProps, TRY_TIMEOUT } from '@/helpers/common.js'

import axios from 'axios'
import api from '@/api.js'

import EditBase from '@/templates/EditBase'
import VueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
    name: 'AddEditComponente',
    components: {
        EditBase,
        VueMultiSelect
    },
    data () {
        return {
            grupoUsuario: {
                id: null,
                nome: '',
                descricao: '',
            },
            listaPermissoes: [],
            listaClassesAlarmes: [],
            listaSeveridades: [{ value: 1, text: 'Somente alarmes'}, { value: 2, text: 'Todas' }],
            errMsg: '',
            loading: true,
            edicao: false,
            saving: false,

            selectedPermitions: [],
            classesAlarmesSeveridade: [],

            multiSelectFilters: [{
                nameAll: 'Selecionar todas',
                nameNotAll: 'Deselecionar todas',
                func() {
                    return true;
                },
            }],
            multiSelectOptions: {
                multi: true,
            },
        }
    },
    computed: {
        isComplete () {
            let t=this.grupoUsuario;
            if (this.classesAlarmesSeveridade.length > 0) {
                return !!(t.nome && this.selectedPermitions.length > 0 && !this.classesAlarmesSeveridade.some(el => !el.classe || !el.severidade));
            }
            return !!(t.nome && this.selectedPermitions.length > 0);
        },
        permissoes () {
            return this.listaPermissoes.map(perm => ({
                id: perm.id,
                name: perm.nome,
            }));
        },
        classesAlarmes () {
            let lista = this.listaClassesAlarmes.filter(classe => {
                return !this.classesAlarmesSeveridade.some(el => (el.classe !== null || undefined) && el.classe.value === classe.id);
            });
            return lista.map(l => ({
                value: l.id,
                text: l.nome,
            }))
        }
    },
    methods: {
        customLabel(selected) {
            return selected.length ? `${selected.length} selecionado${selected.length > 1 ? 's' : ''}` : 'Selecione';
        },
        addClassesAlarmesSeveridade () {
            if (this.classesAlarmesSeveridade.length < this.listaClassesAlarmes.length) {
                this.classesAlarmesSeveridade.push({ id: null, severidade: null, classe: null});
            }
        },
        removeClasse(td) {
            let removeIndex = this.classesAlarmesSeveridade.findIndex(el => el === td);
            this.classesAlarmesSeveridade.splice(removeIndex, 1);
        },
        updateListaPermissoes () {
            this.loading = true;
            return axios.get(api.v1.permissao.list).then(res => {
                this.listaPermissoes = res.data;
            }).catch(reason => {
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
        updateListaClassesAlarmes () {
            this.loading = true;
            return axios.get(api.v1.classeAlarme).then(res => {
                this.listaClassesAlarmes = res.data;
            }).catch(reason => {
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
        permitionsSelect ({ text }) {
            return text;
        },
        AlarmClassSelect ({ text }) {
            return text;
        },
        getGrupoUsuario () {
            let id = this.$route.params.id;
            this.edicao = !!id;
            this.loading = this.edicao; // se for adição loading=false;
            this.limparCampos();
            if (this.edicao) {
                axios.get(api.v1.gruposUsuarios.get(id))
                .then(res => {
                    if (res.data == null) { // grupo de usuarios não existe
                        this.$snotify.error('O grupo de usuários solicitado não existe.', 'Erro');
                        this.$router.replace({name: 'gruposusuarios'});
                    }
                    this.grupoUsuario = res.data;
                    this.selectedPermitions = this.grupoUsuario.permissoes.map(p => ({ id: p.id, name: p.nome }));
                    this.classesAlarmesSeveridade = this.grupoUsuario.classesAlarmes.map(c => {
                        let severidade = c.GrupoUsuario_ClasseAlarme.severidadeMaxima;
                        return {
                            id: c.id,
                            severidade: { value: severidade, text: severidade === 1 ? 'Somente alarmes' : 'Todas'  },
                            classe: { value: c.id, text: c.nome }
                        }
                    });
                    this.loading = false;
                })
                .catch(reason => {
                    console.trace(reason);
                    this.$snotify.warning('Não foi possível buscar o grupo de usuários, tentando novamente...', 'Erro');
                    setTimeout(() => this.getGrupoUsuario(), TRY_TIMEOUT);
                })
            }
        },
        limparCampos() {
            this.grupoUsuario = {
                id: null,
                nome: '',
                descricao: '',
            };
            this.selectedPermitions = [];
            this.classesAlarmesSeveridade = [];
        },
        salvar () {
            this.saving = true;
            this.$snotify.async('Aguarde...', 'Salvando', () => new Promise((resolve, reject) => {

                let payload = copyProps(this.grupoUsuario)(['id', 'nome', 'descricao']);
                //faz a atualização das permissoes
                payload.permissoes = this.selectedPermitions.map(perm => perm.id);
                payload.classesAlarmesSeveridade = this.classesAlarmesSeveridade.map(c => ([c.classe.value, c.severidade.value]));

                let req = this.edicao ? axios.put(api.v1.gruposUsuarios.update, payload)
                                      : axios.post(api.v1.gruposUsuarios.create, payload);
                req.then(res => {
                    this.grupoUsuario = res.data;
                    this.saving = false;
                    if (!this.edicao) {
                        this.limparCampos();
                    } else {
                        this.$router.push({ name: 'gruposusuarios' });
                    }
                    resolve({
                        title: 'Sucesso!',
                        body: 'Grupo de usuários salvo',
                        config: options()
                    });
                }).catch(e => {
                    this.saving = false;
                    reject({
                        title: 'Erro ao salvar',
                        body: e.response.data ? e.response.data.error : e.toString(),
                        config: options(10000)
                    })
                });
            }));
        },
        formSalvar (e) {
            e.preventDefault();
            this.salvar();
            return false;
        },
        remover () {
            const doDelete = () => {
                return axios.delete(api.v1.gruposUsuarios.destroy, { data: { id: this.grupoUsuario.id } })
                .then(res => {
                    return res.data.error ? Promise.reject(res.data.error) : res.data;
                })
                .catch(reason => {
                    if(reason.response && reason.response.data && reason.response.data.error) {
                        this.$swal.showValidationMessage('Falha ao excluir: ' + (reason.response.data.error));
                    }
                    else this.$swal.showValidationMessage('Falha ao excluir: ' + (reason ? reason.toString() : 'motivo desconhecido.'));
                });
            };

            // TODO: transformar em função auxiliar para evitar duplicação de código
            this.$swal.fire({
                title: 'Você tem certeza?',
                text: "A remoção de um grupo de usuários é irreversível",
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true,
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Remover',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !this.$swal.isLoading(),
                showLoaderOnConfirm: true,
                preConfirm: doDelete
            }).then(res => {
                if (res.value) {
                    this.$router.replace('/usuarios/grupos');
                    this.$snotify.success('Grupo de usuários removido', 'Sucesso!');
                }
            })
        },
    },
    watch: {
        '$route': 'getGrupoUsuario',
        selectedPermitions () {
            const permissaoUsuario = this.listaPermissoes.find(p => p.label === 'rwd_usuario');
            if (permissaoUsuario && this.grupoUsuario.id === 1 && !this.selectedPermitions.find(p => p.id === permissaoUsuario.id)) {
                this.selectedPermitions.push(this.permissoes.find(p => p.id === permissaoUsuario.id));
            }
        }
    },
    async mounted () {
        this.updateListaPermissoes();
        this.updateListaClassesAlarmes();
        this.getGrupoUsuario();
    },
}
</script>
<style scoped>
    .w-custom {
        width: 100px;
    }
    .mt-custom {
        margin-top: 33px;
    }
</style>
