<template>
    <GenericList
        ref="lista"
        name="grupo de usuários"
        title="grupos de usuários"
        data-table-state-name="GrupoUsuario"
        add-route="add_grupousuario"
        edit-route="edit_grupousuario"
        :cols="['#', 'Nome', 'Descrição', 'Permissões', 'Notificações de alarme']"
        :cols-name="['id', 'nome', 'descricao', null, null]"
        :cols-map="i => [i.id, i.nome, i.descricao, i.permissoes.sort().map(p=>p.nome), i.classesAlarmes]"
        :api="api"
    >
        <template #Permissões="{ value }">
                <a href="#" @click.prevent="showModalPermissao(value)" title="Exibir lista"><eye-icon size="16" /> {{value.length}} selecionada{{value.length !== 1 ? 's' : ''}}</a>
        </template>
        <template #[notificacoes]="{ value }">
                <a href="#" @click.prevent="showModalNotificacao(value)" title="Exibir lista"><eye-icon size="16" /> {{value.length}} selecionada{{value.length !== 1 ? 's' : ''}}</a>
        </template>
    </GenericList>
</template>


<script>
import GenericList from '@/components/GenericList'
import api from '@/api.js'

export default {
    components: {
        GenericList
    },
    data () {
        return {
            api: api.v1.gruposUsuarios.list,
            notificacoes: 'Notificações de alarme',
        };
    },
    mounted () {
        this.$refs['lista'].update();
    },
    methods: {
        showModalNotificacao (classes) {
            this.$swal({
                title: 'Notificações de alarmes',

                html: '<table class="table"><tr><th scope="col">Severidade</th><th scope="col">Classe de alarme</th></tr>' + (classes.map(c =>
                `<tr><td>${c.GrupoUsuario_ClasseAlarme.severidadeMaxima == 1 ? 'Somente alarmes' : 'Todas'}</td>
                <td>${c.nome}</td></tr>`).join('') || '<tr><td colspan="2">(nenhum)</td></tr>') + '</table>',

                confirmButtonText: 'Fechar',
                confirmButtonColor: '#6c757d',
            })
        },
        showModalPermissao (permissoes) {
            this.$swal({
                title: 'Permissões',

                html: '<table class="table table-sm">' + (permissoes.map(p =>
                `<tr><td>${p}</td></tr>`).join('') || '<tr><td>(nenhum)</td></tr>') + '</table>',

                confirmButtonText: 'Fechar',
                confirmButtonColor: '#6c757d',
            })
        }
    }
}
</script>



